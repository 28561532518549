@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  font-family: "Readex Pro", sans-serif;
} */

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik-VariableFont_wght.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";
  src: url("./assets/fonts/Rubik-VariableFont_wght.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Rubik", sans-serif;
}

.hero {
  background-image: url("./assets/img/hero-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  border-radius: 1.5rem;
}

.app-bg {
  background-image: url("./assets/img/colorful-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
}

.bg-gaming {
  background-image: url("./assets/img/bg-4.jpg");
  background-color: #726e7f;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-height: 100vh;
}

.gradient-bg {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(33, 36, 41, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(33, 36, 41, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(33, 36, 41, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#212429",GradientType=1);
}
